<template>
  <section class="ask" v-if="showComponent">
    <div class="ask__dragAndDrop">
      <iconic name="dragAndDrop" class="ask__dragAndDrop--icon"></iconic>
      <div @click="deleteComponent" v-if="(idx == 1 && howManyQuestions > 1) || idx > 1">
        <iconic name="trashPoll" class="ask__dragAndDrop--trash"></iconic>
      </div>
    </div>
    <div class="ask__select">
      <input type="text" placeholder="Pregunta" class="ask__ask" v-model="questionText" :name="`${name}-${idx}`" @input="validateInput($event, { type: 'text' })" required />
      <div class="ask__select--container">
        <InputPoll
          class="ask__options"
          :options="options"
          :requireImages="requireImages"
          @onOption="showOption"
          @toggleRequireImages="toggleRequireImages"
          :idx="idx"
          :name="name"
          :typeSelect="question?.type"
        />
      </div>
    </div>

    <div class="ask__container" v-if="optionMultiple">
      <div class="ask__description">
        <div class="user-policy-right">
          <ToggleSwitch @change="changeDesc" ref="toggle" :checked="isAskDescriptionActive" :name="`${name}-${idx}`" />
        </div>
        <div class="ask__description--content">
          <textarea
            :disabled="!isAskDescriptionActive"
            class="ask__description--input--textarea"
            cols="45"
            rows="4"
            placeholder="Descripcion opcional de la pregunta"
            :name="`opcionmultiple-${idx}/description`"
          >
          </textarea>
        </div>
      </div>
      <div class="ask__answer">
        <input type="text" placeholder="Opción 1" class="ask__answer--one" :name="`opcionmultiple-${idx}/opcion-0`" @input="validateInput($event, { type: 'text' })" required />
        <div class="ask__answer--icons">
          <div v-if="requireImages"><iconic name="picture"></iconic></div>
          <iconic name="equis"></iconic>
          <iconic name="dragAndDrop"></iconic>
        </div>
      </div>
      <div class="ask__answer">
        <input type="text" placeholder="Opción 2" class="ask__answer--two" :name="`opcionmultiple-${idx}/opcion-1`" @input="validateInput($event, { type: 'text' })" required />
        <div class="ask__answer--icons">
          <div v-if="requireImages"><iconic name="picture"></iconic></div>
          <div><iconic name="equis"></iconic></div>
          <div><iconic name="dragAndDrop"></iconic></div>
        </div>
      </div>
    </div>
    <div class="ask__container" v-if="answerFree">
      <div class="ask__container--textarea"></div>
    </div>
    <div class="ask__container" v-if="checkbox">
      <div class="ask__description">
        <div class="user-policy-right">
          <ToggleSwitch @change="changeDesc" ref="toggle" :checked="isAskDescriptionActive" :name="`${name}-${idx}`" />
        </div>
        <div class="ask__description--content">
          <textarea
            :disabled="!isAskDescriptionActive"
            class="ask__description--input--textarea"
            cols="45"
            rows="4"
            v-model="description"
            placeholder="Descripcion opcional de la pregunta"
            :name="`${name}-${idx}/description`"
          >
          </textarea>
        </div>
      </div>
      <div v-for="(container, index) in answerContainers" :key="index">
        <div class="ask__answer">
          <input
            type="text"
            class="ask__answer--one"
            :placeholder="'Opcion ' + (index + 1)"
            v-model="container.text"
            :name="`${name}-${idx}/opcion-${index}`"
            @input="validateInput($event, { type: 'text' })"
            required
          />
          <input
            accept="image/*"
            type="file"
            class="ask__answer--file"
            :id="'fileInput_' + index + idx"
            style="display: none"
            @change="processFile($event, index)"
            :name="`${name}-${idx}/file-${index}`"
            @input="validateInput($event, { type: 'image' })"
            :required="requireImages"
          />
          <div class="ask__answer--icons">
            <div @click="showInputFile(index, idx)" v-if="requireImages">
              <iconic name="picture"></iconic>
            </div>
            <div @click="deleteOption(index)">
              <iconic v-if="index > 1" name="equis"></iconic>
            </div>
          </div>
        </div>
        <div class="ask__thumbnail">
          <img class="ask__thumbnail--img" :src="container.img" alt="Imagen" v-if="container.img" />
          <span class="ask__thumbnail--delete" @click="deleteFile(index, idx)" v-if="container.img">
            <iconic name="equis" class="delete"></iconic>
          </span>
        </div>
      </div>
      <button type="button" class="ask__add" @click="addOption">Agregar una opción</button>
    </div>
  </section>
</template>
<script>
  import ToggleSwitch from "../widgets/ToggleSwitch.vue";

  export default {
    props: ["idx", "howManyQuestions", "question"],
    name: "Pregunta",
    components: {
      InputPoll: () => import("./Input.vue"),
      ToggleSwitch,
    },
    data() {
      return {
        options: ["Casillas de verificación"],
        answerContainers: [
          { id: 1, text: "", img: "" },
          { id: 2, text: "", img: "" },
        ],
        requireImages: false,
        showComponent: true,
        optionMultiple: false,
        answerFree: false,
        checkbox: true,
        description: "",
        isAskDescriptionActive: false,
        name: "casillasdeverificacion",
        questionText: "",
        dicQuestions: {
          multipleOption: "Casillas de verificación",
          onlyOption: "Opcion multiple",
          freeAnswer: "Respuesta abierta",
        },
      };
    },

    methods: {
      changeDesc() {
        this.isAskDescriptionActive = !this.isAskDescriptionActive;
      },
      addOption() {
        this.answerContainers.push({ id: this.answerContainers.length + 1, text: "", img: "" });
      },
      validateInput: function(evt, { type }) {
        if (type == "image") {
          const fieldInput = document.querySelector(`input[name='${evt.target.className}']`);
          this.$validate.successInput(fieldInput);
          return;
        }
        return this.$validate.validateInput(evt, type);
      },

      toggleRequireImages(flag) {
        this.requireImages = flag;
      },
      deleteOption(index) {
        this.answerContainers.splice(index, 1);
      },
      showInputFile(index, idx) {
        const input = document.getElementById("fileInput_" + index + idx);
        if (input) {
          input.click();
        }
      },
      processFile(event, index) {
        const input = event.target;
        if (input.files && input.files[0]) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.answerContainers[index].img = e.target.result;
          };
          reader.readAsDataURL(input.files[0]);
        }
      },
      deleteFile(index, idx) {
        this.answerContainers[index].img = "";
        const input = document.getElementById("fileInput_" + index + idx);
        if (input) {
          input.value = "";
        }
      },
      deleteComponent() {
        this.showComponent = false;
        this.$emit("deleteComponent", this.idx - 1);
      },
      showOption(option) {
        const cleanedOption = option.replace(/\s+/g, "").toLowerCase();
        this.name = cleanedOption;

        if (option === "Opcion multiple") {
          this.answerFree = false;
          this.optionMultiple = false;
        }
        if (option === "Cuadricula de opcion multiple") {
          this.optionGrid = true;
          this.answerFree = false;
          this.optionMultiple = false;
          this.checkbox = false;
        }
        if (option === "Respuesta abierta") {
          this.answerFree = true;
          this.optionMultiple = false;
          this.optionGrid = false;
          this.checkbox = false;
        }
        if (option === "Casillas de verificacion") {
          this.checkbox = true;
          this.optionGrid = false;
          this.answerFree = false;
          this.optionMultiple = false;
        }
        if (option !== "Opcion multiple") {
          this.optionMultiple = false;
        } else {
          this.optionMultiple = true;
          this.answerFree = false;
          this.optionGrid = false;
          this.checkbox = false;
        }
      },
      handleOptionExist() {
        if (this.question) {
          const { type, options, requireImages, question, description } = this.question;
          this.showOption(this.dicQuestions[type]);
          this.answerContainers = options;
          this.requireImages = requireImages;
          this.questionText = question;
          this.description = description;
        }
      },
    },
    watch: {
      question: {
        immediate: true,
        handler(newVal) {
          if (newVal) {
            this.handleOptionExist();
          }
        },
      },
    },
  };
</script>
<style lang="scss">
  .ask {
    width: 100%;
    max-width: 1440px;
    min-height: 260px;
    height: 100%;
    margin: 2% 0;
    border-radius: 6px;
    background-color: #fff;
    padding: 15px 20px 20px 20px;
    border: 1px solid #00000040;
    box-shadow: 0px 1px 4px 0px #00000040;
    &__dragAndDrop {
      display: flex;
      margin-left: 50%;
      margin-right: 15px;
      padding-bottom: 15px;
      justify-content: space-between;
      &--icon {
        transform: rotate(90deg);
        cursor: pointer;
      }
      &--trash {
        cursor: pointer;
      }
    }
    &__select--container {
      width: 50%;
    }
    &__select {
      display: flex;
    }
    &__description {
      padding: 10px 0;
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
      width: 100%;

      &--content {
        width: 87%;
      }
      &--input--textarea {
        padding: 5px;
        border: 0.5px solid #a8a9a9;
        cursor: text;
        width: 100%;
        text-align: left;
        border-radius: 6px;
        outline: 0 none;
      }
    }
    &__container {
      padding: 10px 0;
      &--textarea {
        width: 100%;
        height: 130px;
        border: 1px solid #999;
        border-radius: 6px;
        &::before {
          content: "Respuesta";
          padding: 10px;
        }
      }
      .ask__thumbnail {
        position: relative;
        &--img {
          width: 120px;
          height: 120px;
          border-radius: 17px;
          box-shadow: 0px 0px 4px 0px #00000040;
        }
        &--delete {
          position: absolute;
          width: 30px;
          height: 30px;
          background: #fff;
          box-shadow: 0px 0px 4px 0px #00000040;
          border-radius: 50%;
          text-align: center;
          padding-top: 6px;
          padding-left: 2px;
          left: 100px;
          top: -10px;
          cursor: pointer;
          .delete {
            color: #a8a9a9;
          }
        }
      }
    }

    .ask__add {
      margin-top: 15px;
      cursor: pointer;
      border: 1px solid #e7e7e7;
      width: max-content;
      padding: 2px 20px;
      border-radius: 16px;
      background: linear-gradient(0deg, #e5e5e5, #e5e5e5), linear-gradient(0deg, #e7e7e7, #e7e7e7);
    }
    &__answer {
      display: flex;
      margin: 20px 0;
      padding: 5px 0;
      justify-content: space-between;
      border-bottom: 1px solid #a8a9a9;
      &--one,
      &--two {
        border: none;
        width: 610px;
      }
      &--icons {
        gap: 15px;
        display: flex;
        margin-right: 15px;
        justify-content: space-between;
      }
    }
    &__ask {
      width: 64%;
      height: 39px;
      border-radius: 4px;
      margin-right: 1%;
      padding: 8px, 8px, 8px, 15px;
      border: 0.5px solid #a8a9a9;
      cursor: text;
      &:focus {
        padding: 5px;
        border: 1px solid #949494;
      }
      &::placeholder {
        padding: 0;
      }
    }
  }
</style>
